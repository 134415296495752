import React from 'react'
import './InfoUs.css';
import ValueImg from '../../Assets/Images/valuesimg.svg'

export const InfoUs = () => {
    return (
        <>
            <div className='container-infoUs'>
            <h3>¿Quienes <span>Somos?</span></h3>
                <div className='valores-us-card'>
                        <div className='info-value-card card1'>
                            <i className="fa-solid fa-box" />
                            <h4>Nuestra Misión</h4>
                            <p>
                            En TPC EXPRESS, nuestra misión es proporcionar servicios de mensajería
                            excepcionales que conecten a personas y empresas de manera eficiente y segura.
                            Nos comprometemos a ofrecer soluciones logísticas innovadoras y confiables que
                            superen las expectativas de nuestros clientes, brindando un valor agregado a 
                            sus operaciones diarias.
                            </p>
                        </div>
                        <div className='info-value-card'>
                            <i className="fa-solid fa-glasses" />
                            <h4>Nuestra Visión</h4>
                            <p>
                            En TPC EXPRESS, nos visualizamos como la primera elección en soluciones
                            de mensajería, liderando la industria con un compromiso inquebrantable 
                            con la eficiencia, confiabilidad y servicio al cliente excepcional. Nos 
                            esforzamos por ser el socio logístico preferido para empresas y particulares.
                            </p>
                        </div>
                        <div className='info-value-card'>
                            <i className="fa-solid fa-table-list" />
                            <h4>Propósito</h4>
                            <p>
                            El propósito fundamental de TPC EXPRESS es facilitar la conexión y el progreso
                            al proporcionar soluciones de mensajería que trascienden la simple entrega de 
                            paquetes. Nos esforzamos por ser el puente que acerca a las personas, las 
                            comunidades y los negocios, creando un impacto positivo en la vida cotidiana. 
                            </p>
                        </div>
                </div>
            </div>
            <div className='valores-infoUs'>
                <h3>Nuestros <span>Valores</span></h3>
                <div className='data-valores-info'>
                    <img src={ValueImg} alt='ValueImg' />
                    <ul>
                        <li>Puntualidad
                            <p>Garantizamos la entrega de tus envíos con la máxima precisión y en el tiempo acordado.</p>
                        </li>
                        <li>Transparencia
                            <p>Creemos en la apertura y honestidad en cada paso del proceso de entrega.</p>
                        </li>
                        <li>Lealtad
                            <p>Valoramos la relación a largo plazo que construimos contigo y tu confianza en nuestros servicios.</p>
                        </li>
                        <li>Excelencia
                            <p>Desde la atención al cliente hasta la manipulación cuidadosa de tus paquetes, cada aspecto de nuestro 
                                servicio se ejecuta con un estándar de calidad insuperable. </p>
                        </li>
                        <li>Escucha
                            <p>No solo entregamos paquetes, sino que también conectamos con tus prioridades. Estamos aquí para escuchar 
                                lo que es más importante para ti en cada envío.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
