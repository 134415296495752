import React from 'react'
import {Link} from 'react-router-dom';
import './Terminos.css';

export const TerminosC = () => {
  return (
    <>
    <div className='container-terminosCondiciones' id='terminos'>
      <Link to='/'>
        <button><i class="fa-solid fa-arrow-left"/> Regresar</button>
      </Link>
        <h1>Términos y Condiciones <span>de Servicio TPC EXPRESS</span></h1>

      <p className='parrafo-principal'>Por favor, lea detenidamente los siguientes términos y condiciones antes de utilizar los servicios de mensajería proporcionados por TPC EXPRESS. Al acceder a nuestros servicios, usted acepta estar sujeto a estos términos y condiciones.</p>

      <h3>Definiciones</h3>
        <p>a. TPC EXPRESS: Hace referencia a la empresa de mensajería y servicios logísticos identificada por la marca comercial TPC EXPRESS.</p>
        <p>b. Usuario: Se refiere a cualquier persona física o jurídica que utiliza los servicios de TPC EXPRESS.</p>
        <p>c. Servicios: Incluyen, pero no se limitan a, la recogida, transporte y entrega de paquetes y documentos proporcionados por TPC EXPRESS.</p>
      <h3>Uso de los Servicios</h3>
        <p>a. Al utilizar nuestros servicios, el Usuario garantiza que tiene la capacidad legal para celebrar contratos vinculantes.</p>
        <p>b. El Usuario se compromete a proporcionar información precisa y completa en relación con los servicios solicitados.</p>
      <h3>Responsabilidad de TPC EXPRESS</h3>
        <p>a. TPC EXPRESS se compromete a realizar los servicios con el debido cuidado y diligencia.</p>
        <p>b. TPC EXPRESS no será responsable por retrasos debidos a circunstancias fuera de su control, como desastres naturales, accidentes, o acciones gubernamentales.</p>
      <h3>Tarifas y Pagos</h3>
        <p>a. El Usuario acepta pagar las tarifas establecidas por TPC EXPRESS por los servicios solicitados.</p>
        <p>b. TPC EXPRESS se reserva el derecho de modificar sus tarifas en cualquier momento, previa notificación al Usuario.</p>
      <h3>Derechos de Propiedad Intelectual</h3>
        <p>a. Todo el contenido, logotipos y materiales proporcionados por TPC EXPRESS son propiedad exclusiva de TPC EXPRESS y no pueden ser utilizados sin autorización.</p>
      <h3>Privacidad y Protección de Datos</h3>
        <p>a. TPC EXPRESS se compromete a proteger la privacidad de los datos del Usuario de acuerdo con las leyes aplicables.</p>
        <p>b. El Usuario acepta que TPC EXPRESS puede utilizar la información proporcionada para mejorar sus servicios y para fines internos.</p>
      <h3>Cancelación y Reembolsos</h3>
        <p>a. El Usuario puede cancelar los servicios solicitados con un aviso previo, sujeto a las tarifas de cancelación aplicables.</p>
        <p>b. Los reembolsos estarán sujetos a la política de cancelación de TPC EXPRESS.</p>
      <h3>Modificaciones de Términos y Condiciones</h3>
        <p>a. TPC EXPRESS se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Los cambios entrarán en vigor después de la notificación al Usuario.</p>
      <h2>Al utilizar los servicios de TPC EXPRESS, el Usuario reconoce haber leído, entendido y aceptado estos términos y condiciones. Estos términos constituyen un contrato legalmente vinculante entre el Usuario y TPC EXPRESS. En caso de alguna 
        pregunta o inquietud, póngase en contacto con nuestro servicio de atención al cliente.</h2>
    </div>
    </>
  )
}