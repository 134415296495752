import './App.css';
import React, {useState} from 'react'
import Navbar from './Components/NavBar/Navbar';
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import { Estandar } from './Pages/Estandar';
import COD from './Pages/COD';
import Nosotros from './Pages/Nosotros';
import { Home } from './Pages/Home';
import Cotizador from './Pages/Cotizador';
import { Envio } from './Pages/Envio';
import { Terminos } from './Pages/Terminos';

function App() {

  const [origen, setOrigen] = useState();
  const [destino, setDestino] = useState();
  const [envio, setEnvio] = useState();
  const [paquetes, setPaquetes] = useState();
  const [peso, setPeso] = useState();
  const [precioProducto, setPrecioProducto] = useState();
  const [pago, setPago] = useState();
  const [total, setTotal] = useState();

  const [nombreRecoleccion, setNombreRecoleccion] = useState();                       
  const [telefonoRecoleccion, setTelefonoRecoleccion] = useState();
  const [mailRecoleccion, setMailRecoleccion] = useState();
  const [direccionRecoleccion, setDireccionRecoleccion] = useState();
  const [fechaRecoleccion, setFechaRecoleccion] = useState();
  const [instruccionesRecoleccion, setInstruccionesRecoleccion] = useState();
  const [nombreDestino, setNombreDestino] = useState();
  const [telefonoDestino, setTelefonoDestino] = useState();
  const [mailDestino, setMailDestino] = useState();
  const [direccionDestino, setDireccionDestino] = useState();
  const [fechaDestino, setFechaDestino] = useState();
  const [instruccionesDestino, setInstruccionesDestino] = useState();

  const [cobroEnvio, setCobroEnvio] = useState();
  const [comision, setComision] = useState();

  const [selected, setSelected] = useState();



return (
  <Router>
    <Navbar />
    <Routes>
    <Route path='/' element={<Home />}/>
    <Route path='/servicios' element={<Services />} />
    <Route path='/contact-us' element={<Contact />} />
    <Route path='/estandar' element={<Estandar />} />
    <Route path='/COD' element={<COD />} />
    <Route path='/nosotros' element={<Nosotros />} />

    <Route path='/cotizador' element={<Cotizador 
      origen={origen} setOrigen={setOrigen}
      destino={destino} setDestino={setDestino}
      envio={envio} setEnvio={setEnvio}
      paquetes={paquetes} setPaquetes={setPaquetes}
      peso={peso} setPeso={setPeso}
      precioProducto={precioProducto} setPrecioProducto={setPrecioProducto}
      pago={pago} setPago={setPago}
      selected={selected} setSelected={setSelected}
      cobroEnvio={cobroEnvio} setCobroEnvio={setCobroEnvio}
      comision={comision} setComision={setComision}
      total={total} setTotal={setTotal}
    />} />

    <Route path='/envio' element={<Envio
      origen={origen} setOrigen={setOrigen} destino={destino} setDestino={setDestino}
      envio={envio} setEnvio={setEnvio} paquetes={paquetes} setPaquetes={setPaquetes}
      peso={peso} setPeso={setPeso} precioProducto={precioProducto} setPrecioProducto={setPrecioProducto}
      pago={pago} setPago={setPago} selected={selected} setSelected={setSelected}
      nombreRecoleccion={nombreRecoleccion} setNombreRecoleccion={setNombreRecoleccion}
      nombreDestino={nombreDestino} setNombreDestino={setNombreDestino}
      telefonoRecoleccion={telefonoRecoleccion} setTelefonoRecoleccion={setTelefonoRecoleccion}
      telefonoDestino={telefonoDestino} setTelefonoDestino={setTelefonoDestino}
      mailRecoleccion={mailRecoleccion} setMailRecoleccion={setMailRecoleccion}
      mailDestino={mailDestino} setMailDestino={setMailDestino}
      direccionRecoleccion={direccionRecoleccion} setDireccionRecoleccion={setDireccionRecoleccion}
      direccionDestino={direccionDestino} setDireccionDestino={setDireccionDestino}
      fechaRecoleccion={fechaRecoleccion} setFechaRecoleccion={setFechaRecoleccion}
      fechaDestino={fechaDestino} setFechaDestino={setFechaDestino}
      instruccionesRecoleccion={instruccionesRecoleccion} setInstruccionesRecoleccion={setInstruccionesRecoleccion}
      instruccionesDestino={instruccionesDestino} setInstruccionesDestino={setInstruccionesDestino}
      total={total} setTotal={setTotal}
    />} />
    <Route path='/terminos' element={<Terminos/>} />
    </Routes>
  </Router>
  )
}

export default App;
