import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-left'>
                <h3>TPC <span>EXPRESS</span></h3>
                <p className='footer-links'>
                    <Link to='/nosotros' className='a'>Nosotros</Link>
                    |
                    <Link to='/servicios' className='a'>Servicios</Link>
                    |
                    <Link to='/contact-us' className='a'>Contacto</Link>
                </p>
                <p className='footer-company-name'>
                    Copyright © 2023 <strong>TPC EXPRESS
                    </strong> Todos los derechos reservados
                </p>
            </div>
            <div className='footer-center'>
                <div>
                    <i className="fa-solid fa-map-pin" />
                    <p><span>2 calle A 42-62 zona 3 de Mixco</span></p>
                </div>
                <div>
                    <i className="fa-solid fa-phone" />
                    <Link to='tel:22981217' className='a-info'>2298-1217</Link>
                </div>
                <div>
                    <i className="fa-solid fa-envelope" />
                    <Link to='mailto:info@tpcxpress.com?subject=Contacto a través de la pagina' className='a-info'>info@tpcxpress.com</Link>
                </div>
            </div>
            <div className='footer-right'>          
                <p className='footer-company-about'>
                    <span>ACERCA DE LA COMPAñIA</span>
                    <Link to='/terminos' className='terms'><strong>Términos y Condiciones</strong></Link>
                </p>
                <div className='footer-icons'>
                    <Link to='https://www.facebook.com/profile.php?id=100076178888075' target='_blank'><i className="fa-brands fa-facebook" /></Link>
                    <Link to='https://www.instagram.com/tpc_express/' target='_blank'><i className="fa-brands fa-instagram" /></Link>
                    <Link to='https://wa.me/58644597?text=Hola! Necesito Información' target='_blank'><i className="fa-brands fa-whatsapp" /></Link>
                </div> 
            </div>
        </footer>
    )
}