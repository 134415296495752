import React from 'react'
import { Link } from 'react-router-dom';
import './BotonCotizador.css';

export const BotonCotizador = () => {
    return (
        <div className='btn-cotizador-container'>
            <div className='card-btn-cotizador'>
                <i className='fas fa-calculator' />
                <p>Calcula el precio de tu envío, con las mejores
                    tarifas de Guatemala
                </p>
                <div className='container-btn-cotizar'>
                    <Link to='/cotizador'>
                        <button className='btn-cotizar'>Cotizar</button>
                    </Link>
                </div>
            </div>
            <div className='card-btn-cotizador'>
                <i className="fa-brands fa-square-whatsapp fa-btn"/>
                <p>¡Habla con nosotros, será un gusto atenderte!
                </p>
                <div className='container-btn-cotizar'>
                    <Link to='https://wa.me/58644597?text=Hola! Necesito Información' target='_blank'>
                        <button className='btn-cotizar'>Contáctanos</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
