import React from 'react'
import { Link } from 'react-router-dom'
import './BotonServicios.css'

export const BotonServicios = () => {
    return (
        <div className='container-btn-solicita-servicio'>
            <Link to='/cotizador'>
                <button className='btn-solicita-servicio'>
                    <i className="fa-solid fa-dolly" />
                    Solicita el Servicio
                </button>
            </Link>
        </div>
    )
}