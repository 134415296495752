import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from '../Dropdown/Dropdown';
import Logo from '../../Assets/Logo/LOGO.png';

function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobilMenu = () => setClick(false);

    const onMouseEnter = () => {
        if(window.innerWidth < 1480){
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if(window.innerWidth < 950){
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };


    return (
            <nav className='navbar'>
                <Link to='/' className='navbar-logo'>
                    <img src={Logo} alt='Logo' />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-xmark' : 'fas fa-bars-staggered'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobilMenu}>
                            Inicio
                        </Link>
                    </li>
                    <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <Link to='/servicios' className='nav-links' onClick={closeMobilMenu}>
                            Servicios <i className='fas fa-caret-down' />
                        </Link>
                        {dropdown && <Dropdown onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={closeMobilMenu}/>}
                    </li>
                    <li className='nav-item'>
                        <Link to='/nosotros' className='nav-links' onClick={closeMobilMenu}>
                            Nosotros
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact-us' className='nav-links-mobile' onClick={closeMobilMenu}>
                            Contáctenos
                        </Link>
                    </li>
                </ul>
                <Link to='/contact-us' className='nav-item-btn'>Contáctenos</Link>
            </nav>
    )
}

export default Navbar;