export const DataUbicaciones = [
        {label: 'Mixco', value: 'Mixco'},
        {label: 'Guatemala', value: 'Guatemala'},
        {label: 'Fraijanes', value: 'Fraijanes'},
        {label: 'Villa Nueva', value: 'Villa Nueva'},
        {label: 'Villa Canales', value: 'Villa Canales'},
        {label: 'San Jose Pinula', value: 'San Jose Pinula'},
        {label: 'San Miguel Petapa', value: 'San Miguel Petapa'},
        {label: 'Santa Catarina Pinula', value: 'Santa Catarina Pinula'},
]

export const PaquetesEnvio = ['Sobres', 'Paquetes'];

export const pesoPaquete = ['1 a 10 lbs'];

export const cantidadPaquetesEnviados = ['1'];