import React from 'react'
import { SeleccionServicio } from '../Components/Cotizador/SeleccionServicio'
import '../Components/Cotizador/Cotizador.css';

export default function Cotizar ({
    origen,
    setOrigen,
    destino,
    setDestino,
    envio,
    setEnvio,
    paquetes,
    setPaquetes,
    peso,
    setPeso,
    precioProducto,
    setPrecioProducto,
    pago,
    setPago,
    selected,
    setSelected,
    cobroEnvio,
    setCobroEnvio,
    comision,
    setComision,
    total,
    setTotal
}) {

    return(
        <>
            <SeleccionServicio 
                origen={origen} setOrigen={setOrigen}
                destino={destino} setDestino={setDestino}
                envio={envio} setEnvio={setEnvio}
                paquetes={paquetes} setPaquetes={setPaquetes}
                peso={peso} setPeso={setPeso}
                precioProducto={precioProducto} setPrecioProducto={setPrecioProducto}
                pago={pago} setPago={setPago}
                selected={selected} setSelected={setSelected}
                cobroEnvio={cobroEnvio} setCobroEnvio={setCobroEnvio}
                comision={comision} setComision={setComision}
                total={total} setTotal={setTotal}            />
        </>
    )
}