import axios from 'axios';

export const SendMail = async ({fullName,email,phone,interes,terms,message,setSend})=> {
    try {
        const datas = {fullName, email, phone, interes, terms, message}
        let res = await axios.post(
            `https://servertpc-production.up.railway.app/send`, datas);
        if (res) {
            setSend(res.data);
        }
    } catch(error){
        setSend(error.message);
    }
};

export const SendEnvio = async ({origen,destino,envio,paquetes,peso,precioProducto,pago,selected,
    nombreRecoleccion,nombreDestino,telefonoRecoleccion,telefonoDestino,mailRecoleccion,mailDestino,
    direccionRecoleccion,direccionDestino,fechaRecoleccion,fechaDestino,instruccionesRecoleccion,
    instruccionesDestino,tipoPago,nit,nombreNit,number,direccionFactura,banco,tipoCuenta,numeroCuenta,
    nombreCuenta,total, setSen}) => {
    try {
        const datas = {origen,destino,envio,paquetes,peso,precioProducto,pago,selected,
            nombreRecoleccion,nombreDestino,telefonoRecoleccion,telefonoDestino,mailRecoleccion,mailDestino,
            direccionRecoleccion,direccionDestino,fechaRecoleccion,fechaDestino,instruccionesRecoleccion,
            instruccionesDestino,tipoPago,nit,nombreNit,number,direccionFactura,banco,tipoCuenta,numeroCuenta,
            nombreCuenta,total}
        let res = await axios.post(
            `https://servertpc-production.up.railway.app/solicitud`, datas);
        if (res) {
            setSen(res.data);
        }
    } catch(error){
        setSen(error.message);
    }
};

