import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import { DataUbicaciones } from './Data'
import { PaquetesEnvio } from './Data';
import { pesoPaquete } from './Data';
import { cantidadPaquetesEnviados } from './Data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from '../Toast/Toast';
import { calcularTotal } from './FuncionCalcular';
import { ResultadoEstandar } from './ResultadoEstandar'
import './Cotizador.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const EstandarForm = ({
    origen,
    setOrigen,
    destino,
    setDestino,
    envio,
    setEnvio,
    paquetes,
    setPaquetes,
    peso,
    setPeso,
    pago,
    setPago,
    cobroEnvio,
    precioProducto,
    setPrecioProducto,
    total,
    setTotal
}) => {

    const [err, setErr] = useState();
    const [resultadoVisible, setResultadoVisible] = useState(false);
    const [cobroDestino, setCobroDestino] = useState();

    const productoPrecio = parseInt(precioProducto);

    const seguro = parseInt((productoPrecio * 0.01).toFixed(0));
    cobroEnvio = calcularTotal(paquetes)

    const renderTool = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Si deseas enviar varios paquetes realiza las solicitudes de forma
            individual por seguridad de tus envios.
        </Tooltip>
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!origen || 
            !destino || 
            !envio || 
            !paquetes ||
            !peso ||
            !pago){
            setErr(true);
            return;
        }
        setErr(false);
        toast.success('Cotización Realizada!');

        const Total = calcularTotal(paquetes) + seguro + cobroDestino;
        setTotal(Total);
    }

    useEffect(() => {
        total >= 0
        ? setResultadoVisible(true)
        : setResultadoVisible(false);
    },[total]);

    useEffect(() => {
        pago === 'Destino'
        ? setCobroDestino(3)
        : setCobroDestino(0);
    }, [pago])

    return (
        <div className='container-form--cotizador'>
            <Toast />
            <form className='form-cotizador' onSubmit={handleSubmit}>
                <Select 
                    placeholder='Selecciona Un Origen ...'
                    options= {DataUbicaciones}
                    onChange={ (e) => setOrigen(e.value) }
                />
                <Select 
                    placeholder='Selecciona Un Destino ...'
                    options= {DataUbicaciones}
                    onChange={ (e) => setDestino(e.value) }
                />
                <Select 
                    placeholder='¿Que Envías?'
                    options= {PaquetesEnvio.map(item => ({ label: item, value: item}))}
                    onChange={ (e) => setEnvio(e.value) }
                />
                <React.Fragment>
                <label className='label-info'>Más Información de Paquetes
                        <OverlayTrigger
                            className='parrafo-informacion'
                            placement="right"
                            overlay={renderTool}
                        >
                            <span className='tooltip-btn'>
                                <i className="fa-solid fa-question"></i>
                            </span>
                        </OverlayTrigger>
                    </label>
                <Select 
                    placeholder='Cantidad de Paquetes'
                    options= {cantidadPaquetesEnviados.map(item => ({ label: item, value: item}))}
                    onChange={ (e) => setPaquetes(e.value) }
                />
                </React.Fragment>
                <React.Fragment>
                    <label className='label-info'>Dimension maxima de Paquete 10 pulgadas x 5 pulgadas *</label>
                    <Select 
                        placeholder='Peso Estimado lbs'
                        options={pesoPaquete.map(item => ({ label: item, value: item}))}
                        onChange={ (e) => setPeso(e.value) } 
                />
                </React.Fragment>
                <div className='input-precio-producto'>
                    <input type='number' placeholder='Ingresa Monto de Producto' onChange={(e) => setPrecioProducto(e.target.value)}/>
                </div>  
                <div className='input-switch-pago'>
                    <label id='label-pago'>Donde Pagas?</label>

                    <label>
                    <input type='radio' name='pago' value='Origen' onChange={(e) => setPago(e.target.value)} />
                        Origen</label>

                    <label>
                    <input type='radio' name='pago' value='Destino' onChange={(e) => setPago(e.target.value)} />
                        Destino (+Q3.00)</label>
                </div>
                <button type="submit" className='btn-cotizador'>Cotizar</button>
                {(err) ? <p className='error-msg'>Todos los campos son obligatorios!</p> : ''}
            </form>
            <div>
                {resultadoVisible && <ResultadoEstandar
                    origen={origen} destino={destino}
                    envio={envio} paquetes={paquetes}
                    peso={peso} pago={pago}
                    cobroEnvio={cobroEnvio}
                    cobroDestino={cobroDestino}
                    seguro={seguro} total={total}
                />}
            </div>
        </div>
    )
}