import React from 'react'
import Card from 'react-bootstrap/Card';
import './Equipo.css'
import Equipo1 from '../../Assets/Images/tpc1.webp'
import Equipo2 from '../../Assets/Images/tpc3.webp'

export const Equipo = () => {
    return (
        <div className='container-equipo-card'>
            <Card className="bg-dark text-white">
                <Card.Img src={Equipo1} alt="Card image" />
                <Card.ImgOverlay>
                    <Card.Title>Talento Humano</Card.Title>
                    <Card.Text>
                    Colaboradores que con pasión y compromiso 
                    trabajan para hacer tus entregas más rápidas y seguras.
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
            <Card className="bg-dark text-white">
                <Card.Img src={Equipo2} alt="Card image" />
                <Card.ImgOverlay>
                    <Card.Title>Equipo Logistico</Card.Title>
                    <Card.Text>
                        Equipo adaptado para mayor seguridad y rapidez en cada entega.
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
        </div>
    )
}
