import React from 'react'
import './CardServicios.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Servicio1 from '../../Assets/Images/tpc2.webp'
import Servicio2 from '../../Assets/Images/moto4tpc.webp'

export const CardServicios = () => {
    return (
        <div className='container-card-servicios'>
            <h1>Servicios <span>TPC EXPRESS</span></h1>
            <div className='c-c-s'>
            <Card style={{ width: '20rem' }}>
                <Card.Img variant="top" src={Servicio1} alt='Servicio COD'/>
                <Card.Body>
                    <Card.Title>C.O.D</Card.Title>
                    <Card.Text>
                        Servicio de Mensajería, mas cobro de producto en el lugar de destino.
                    </Card.Text>
                    <Link to='/COD'>
                        <Button variant="primary"><i className="fas fa-arrow-right" /></Button>
                    </Link>
                </Card.Body>
            </Card>
            <Card style={{ width: '20rem' }}>
                <Card.Img variant="top" src={Servicio2} alt='Servicio Estandar' />
                <Card.Body>
                    <Card.Title>Estandar</Card.Title>
                    <Card.Text>
                        Servicio regular de Mensajería y Paquetería en territorio de cobertura.
                    </Card.Text>
                    <Link to='/estandar'>
                        <Button variant="primary"><i className="fas fa-arrow-right" /></Button>
                    </Link>
                </Card.Body>
            </Card>
            </div>
        </div>
    )
}
