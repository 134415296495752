export function calcularTotal (selectCantidad) {

        const cantidadPaquetes = parseInt(selectCantidad);

        let totalEnvio;

        switch (cantidadPaquetes) {
            case 1: 
                totalEnvio = 30;
                break;
            case 2:
                totalEnvio = 60;
                break;
            case 3:
                totalEnvio = 90;
                break;
            case 4:
                totalEnvio = 120;
                break;
            case 5:
                totalEnvio = 150;
                break
            default:
                break;
        }

        return totalEnvio;

}