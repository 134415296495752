import React from 'react'
import './Cotizador.css';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const ResultadoCOD = ({
    origen,
    destino,
    envio,
    paquetes,
    peso,
    precioProcuto,
    seguro,
    cobroEnvio,
    comision,
    total 
    }) => {

        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              El monto de seguro es para cubirir tu producto en caso de siniestro.
            </Tooltip>
          );

        return (
            <div className='container-resultado'>
            <div className='resultado'>
                <h2>Cotización :</h2>
                <p>Origen Seleccionado: <span>{origen}</span></p>
                <p>Destino Seleccionado: <span>{destino}</span></p>
                <p>¿Que se Envía? : <span>{envio}</span></p>
                <p>Cantidad Paquetes a Enviar: <span>{paquetes}</span></p>
                <p>Peso de Paquete: <span>{peso}</span></p>
                <p>Precio Producto a Cobrar: <span>Q{precioProcuto}</span></p>
                <p>Envío: <span>Q{cobroEnvio}</span></p>
                <p>Comisión COD: <span>Q{comision}</span></p>

                <p>
                <OverlayTrigger
                        className='parrafo-informacion'
                        placement="left"
                        overlay={renderTooltip}
                    >
                    <span className='tooltip-btn'>
                            <i className="fa-solid fa-question"></i>
                    </span>
                </OverlayTrigger>
                
                Seguro (1%) : <span>Q{seguro}</span></p>
                <h3>Total Cotización: <span>Q{total}</span></h3>

                <Link to='/envio' className='link-btn-envio'>
                    <button>Solicitar Recolección<i className="fa-solid fa-boxes-packing"></i></button>
                </Link>
            </div>            
            </div>
        );
}