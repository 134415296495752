import React from 'react'
import { EnvioSolicitud } from '../Components/Envio/EnvioSolicitud'

export const Envio = ({
    origen,
    setOrigen,
    destino,
    setDestino,
    envio,
    setEnvio,
    paquetes,
    setPaquetes,
    peso,
    setPeso,
    precioProducto,
    setPrecioProducto,
    pago,
    setPago,
    selected,
    setSelected,
    nombreRecoleccion,
    setNombreRecoleccion,
    nombreDestino,
    setNombreDestino,
    telefonoRecoleccion,
    setTelefonoRecoleccion,
    telefonoDestino,
    setTelefonoDestino,
    mailRecoleccion,
    setMailRecoleccion,
    mailDestino,
    setMailDestino,
    direccionRecoleccion,
    setDireccionRecoleccion,
    direccionDestino,
    setDireccionDestino,
    fechaRecoleccion,
    setFechaRecoleccion,
    fechaDestino,
    setFechaDestino,
    instruccionesRecoleccion,
    setInstruccionesRecoleccion,
    instruccionesDestino,
    setInstruccionesDestino,
    total,
    setTotal
}) => {
    return (
        <div>
            <EnvioSolicitud 
                origen={origen} setOrigen={setOrigen} destino={destino} setDestino={setDestino}
                envio={envio} setEnvio={setEnvio} paquetes={paquetes} setPaquetes={setPaquetes}
                peso={peso} setPeso={setPeso} precioProducto={precioProducto} setPrecioProducto={setPrecioProducto}
                pago={pago} setPago={setPago} selected={selected} setSelected={setSelected}
                nombreRecoleccion={nombreRecoleccion} setNombreRecoleccion={setNombreRecoleccion}
                nombreDestino={nombreDestino} setNombreDestino={setNombreDestino}
                telefonoRecoleccion={telefonoRecoleccion} setTelefonoRecoleccion={setTelefonoRecoleccion}
                telefonoDestino={telefonoDestino} setTelefonoDestino={setTelefonoDestino}
                mailRecoleccion={mailRecoleccion} setMailRecoleccion={setMailRecoleccion}
                mailDestino={mailDestino} setMailDestino={setMailDestino}
                direccionRecoleccion={direccionRecoleccion} setDireccionRecoleccion={setDireccionRecoleccion}
                direccionDestino={direccionDestino} setDireccionDestino={setDireccionDestino}
                fechaRecoleccion={fechaRecoleccion} setFechaRecoleccion={setFechaRecoleccion}
                fechaDestino={fechaDestino} setFechaDestino={setFechaDestino}
                instruccionesRecoleccion={instruccionesRecoleccion} setInstruccionesRecoleccion={setInstruccionesRecoleccion}
                instruccionesDestino={instruccionesDestino} setInstruccionesDestino={setInstruccionesDestino}
                total={total} setTotal={setTotal}
            />
        </div>
    )
}