import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './TabCobertura.css';
import { Link } from 'react-router-dom';

export const TabCobertura = () => {
    return (
        <div className='info-tab-cod'>
            <Tabs
                defaultActiveKey="home"
                id="justify-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title="Cobertura Local">
                    <ul>
                        <li>Ciudad Guatemala</li>
                        <li>Mixco</li>
                        <li>San José Pinula</li>
                        <li>Santa Catarina Pinula</li>
                        <li>San Miguel Petapa</li>
                        <li>Villa Nueva</li>
                    </ul>
                </Tab>
                <Tab eventKey='contacto' title='¡Háblanos!' className='tab-icon'>
                    <Link to='https://wa.me/58644597?text=Hola! Necesito Información' target='_blank'>
                    <i class="fa-brands fa-square-whatsapp" />
                    </Link>
                    <p>¡Será un gusto atenderte!</p>
                    <h5>Llámanos</h5>
                    <p>2298-1217</p> 
                </Tab>
            </Tabs>
        </div>
    )
}