import React from 'react'
import jsPdf from 'jspdf';
import './Pdf.css';

const Pdf = ({
  destino,
  pago,
  selected,
  nombreDestino,
  telefonoDestino,
  direccionDestino,
  fechaDestino,
  instruccionesDestino,
  tipoPago,
  number,
  total
}) => {

  const generarPDF = () => {
    const doc = new jsPdf();

    doc.text(`Número de Envio: ${number}`, 80, 20);
    doc.text(`Servicio: ${selected}`, 50, 30);
    doc.text(`Lugar de Destino: ${destino}`, 10, 40);
    doc.text(`Nombre de Quien Recibe: ${nombreDestino}`, 10, 50);
    doc.text(`Número de Teléfono: ${telefonoDestino}`, 10, 60);
    doc.text(`Dirección De Entrega: ${direccionDestino}`, 10, 70);
    doc.text(`Instrucciones Especiales: ${instruccionesDestino}`, 10, 80);
    doc.text(`Donde Pagará: ${pago}`, 10, 90);
    doc.text(`Tipo de Pago: ${tipoPago}`, 10, 100);
    doc.text(`FECHA DE ENTREGA: ${fechaDestino?.toLocaleDateString()}`, 10, 110);
    doc.text(`TOTAL: Q${total}`, 10, 120);

    //guardar PDF
    doc.save(`Envio ${number}`);
  }

  return (
    <div>
      <button type='button' className='boton-descarga'
      onClick={generarPDF}>Descargar PDF</button>
    </div>
  )
}

export default Pdf
