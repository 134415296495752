import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import { DataUbicaciones } from './Data'
import { PaquetesEnvio } from './Data';
import { pesoPaquete } from './Data';
import { cantidadPaquetesEnviados } from './Data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from '../Toast/Toast';
import { calcularTotal } from './FuncionCalcular';
import { ResultadoCOD } from './ResultadoCOD'
import './Cotizador.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const CodForm = ({
    origen,
    setOrigen,
    destino,
    setDestino,
    envio,
    setEnvio,
    paquetes,
    setPaquetes,
    peso,
    setPeso,
    precioProducto,
    setPrecioProducto,
    cobroEnvio,
    setCobroEnvio,
    comision,
    setComision,
    total,
    setTotal
}) => {

    const [error, setError] = useState();
    const [resultadoVisible, setResultadoVisible] = useState(false);

    const productoPrecio = parseInt(precioProducto);

    const seguro = parseInt((productoPrecio * 0.01).toFixed(0));
    const comisionCod = parseInt((productoPrecio * 0.03).toFixed(0));

    comision = comisionCod
    cobroEnvio = calcularTotal(paquetes)

    const renderTool = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Si deseas enviar varios paquetes realiza las solicitudes de forma
            individual por seguridad de tus envios.
        </Tooltip>
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!origen || 
            !destino || 
            !envio || 
            !paquetes ||
            !peso ||
            !precioProducto){
            setError(true);
            return;
        }
        setError(false);
        toast.success('Cotización Realizada!');

        const Total = comisionCod + productoPrecio + calcularTotal(paquetes) + seguro;
        setTotal(Total);

    }

    useEffect(() => {
        total > 0
        ? setResultadoVisible(true)
        : setResultadoVisible(false);
    },[total]);

    return (
        <div className='container-form--cotizador'>
            <Toast />
            <form className='form-cotizador' onSubmit={handleSubmit}>
                <Select 
                    placeholder='Selecciona Un Origen ...'
                    options= {DataUbicaciones}
                    onChange={ (e) => setOrigen(e.value) }
                />
                <Select 
                    placeholder='Selecciona Un Destino ...'
                    options= {DataUbicaciones}
                    onChange={ (e) => setDestino(e.value) }
                />
                <Select 
                    placeholder='¿Que Envías?'
                    options= {PaquetesEnvio.map(item => ({ label: item, value: item}))}
                    onChange={ (e) => setEnvio(e.value) }
                />
                <React.Fragment>
                    <label className='label-info'>Más Información de Paquetes
                        <OverlayTrigger
                            className='parrafo-informacion'
                            placement="right"
                            overlay={renderTool}
                        >
                            <span className='tooltip-btn'>
                                <i className="fa-solid fa-question"></i>
                            </span>
                        </OverlayTrigger>
                    </label>
                <Select 
                    placeholder='Cantidad de Paquetes'
                    options= {cantidadPaquetesEnviados.map(item => ({ label: item, value: item}))}
                    onChange={ (e) => setPaquetes(e.value) }
                />
                </React.Fragment>
                <React.Fragment>
                    <label className='label-info'>Dimension maxima de Paquete 10 pulgadas x 5 pulgadas *</label>
                    <Select 
                        placeholder='Peso Estimado lbs'
                        options={pesoPaquete.map(item => ({ label: item, value: item}))}
                        onChange={ (e) => setPeso(e.value) } 
                />
                </React.Fragment>
                <div className='input-precio-producto'>
                    <input type='number' placeholder='Ingresa Monto de Producto a Cobrar' onChange={(e) => setPrecioProducto(e.target.value)}/>
                </div>
                <button type="submit" className='btn-cotizador'>Cotizar</button>
                {(error) ? <p className='error-msg'>Todos los campos son obligatorios!</p> : ''}
            </form>
            <div>
                {resultadoVisible && <ResultadoCOD 
                    origen={origen} destino={destino}
                    envio={envio} paquetes={paquetes}
                    peso={peso} precioProcuto={precioProducto}
                    cobroEnvio={cobroEnvio} comision={comision}
                    total={total} seguro={seguro}
                />}
            </div>
        </div>
    )
}