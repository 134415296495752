import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import "./EnvioSolicitud.css";
import Select from "react-select";
import { RevisionEnvio } from "./RevisionEnvio";
import MyVerticallyCenteredModal from "../ModalPdf/Modal";
import { Bancos, Nit, TipoPago } from "./DataBanco";
import { TipoCuenta } from "./DataBanco";
import { toast } from "react-toastify";
import { SendEnvio } from "../../API/api";
import { Toast } from "../Toast/Toast";
import { BackToHome } from "./BackToHome";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {addDays} from "date-fns";
import es from "date-fns/locale/es";
import { Link } from "react-router-dom";

registerLocale("es", es);

const getRandomNumberFromApi = async () => {
  const res = await fetch(
    "https://www.random.org/integers/?num=1&min=100&max=100000&col=1&base=10&format=plain&rnd=new"
  );
  const numberString = await res.text();

  return numberString;
};

export const EnvioSolicitud = ({
  origen,
  setOrigen,
  destino,
  setDestino,
  envio,
  setEnvio,
  paquetes,
  setPaquetes,
  peso,
  setPeso,
  precioProducto,
  setPrecioProducto,
  pago,
  setPago,
  selected,
  setSelected,
  nombreRecoleccion,
  setNombreRecoleccion,
  nombreDestino,
  setNombreDestino,
  telefonoRecoleccion,
  setTelefonoRecoleccion,
  telefonoDestino,
  setTelefonoDestino,
  mailRecoleccion,
  setMailRecoleccion,
  mailDestino,
  setMailDestino,
  direccionRecoleccion,
  setDireccionRecoleccion,
  direccionDestino,
  setDireccionDestino,
  fechaRecoleccion,
  setFechaRecoleccion,
  fechaDestino,
  setFechaDestino,
  instruccionesRecoleccion,
  setInstruccionesRecoleccion,
  instruccionesDestino,
  setInstruccionesDestino,
  total,
  setTotal
}) => {
  const [tipoPagoVisible, setTipoPagoVisible] = useState(true);
  const [cuentaBancariaVisible, setCuentaBancariaVisible] = useState()
  const [showModal, setShowModal] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [submit, setSubmit] = useState(true);
  const [tipoPago, setTipoPago] = useState();
  const [terminos, setTerminos] = useState();

  const [nit, setNit] = useState();
  const [nombreNit, setNombreNit] = useState();
  const [direccionFactura, setDireccionFactura] = useState();
  const [deseaNit, setDeseaNit] = useState();
  const [nitVisible, setNitVisible] = useState(false);

  const [banco, setBanco] = useState();
  const [tipoCuenta, setTipoCuenta] = useState();
  const [numeroCuenta, setNumeroCuenta] = useState();
  const [nombreCuenta, setNombreCuenta] = useState();

  const [number, setNumber] = useState();
  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [sen, setSen] = useState();

  useEffect(() => {
    getRandomNumberFromApi()
      .then(setNumber)
      .catch((error) => setError(error.message));
  }, []);

  useEffect(() => {
    if(selected === 'COD'){
      setPago('Destino');
      setTipoPago('Efectivo');
    }
  },[selected, setPago, tipoPago])

  useEffect(() => {
    if(selected === 'ESTANDAR'){
      setBanco('N/A');
      setTipoCuenta('N/A');
      setNumeroCuenta('N/A');
      setNombreCuenta('N/A');
    }
  },[banco, tipoCuenta, numeroCuenta, nombreCuenta, selected])

  useEffect(() => {
    selected === 'ESTANDAR' ? setCuentaBancariaVisible(false) : setCuentaBancariaVisible(true);
  },[selected])

  useEffect(() => {
    selected === "COD" || pago === 'Destino' ? setTipoPagoVisible(false) : setTipoPagoVisible(true);
  }, [selected, pago]);

  useEffect(() => {
    selected === "COD" || pago === 'Destino'
      ? setTipoPago('Efectivo')
      : setTipoPago(tipoPago)
  },[selected, pago, tipoPago])

  useEffect(() => {
    deseaNit === "NIT" ? setNitVisible(true) : setNitVisible(false)
  },[deseaNit])

  useEffect(() => {
    if(deseaNit === "C/F"){
      setNit("CF")
      setNombreNit("N/A")
      setDireccionFactura("N/A")
    }
  },[deseaNit])

  useEffect(() => {
    if(sen){
      toast.success(sen.msg);
      setShowModal(true);
      setPdf(true);
      setSubmit(false);
      setTerminos();
    }
  },[sen,showModal,pdf,submit,terminos]);

  const handleSubmit = (event) => {
    event.preventDefault();
  if (!origen || !destino || !envio || !paquetes || !peso || !precioProducto || !pago || !nombreRecoleccion ||
    !nombreDestino || !telefonoRecoleccion || !telefonoDestino || !mailRecoleccion || !mailDestino ||
    !direccionRecoleccion || !direccionDestino || !fechaRecoleccion || !fechaDestino || !instruccionesRecoleccion ||
    !instruccionesDestino || !tipoPago || !nit || !nombreNit || !direccionFactura || !banco || !tipoCuenta || !numeroCuenta ||
    !nombreCuenta || !terminos) {
      setErrorMessage(true)
      return;
    } else{
      setErrorMessage(false)
      SendEnvio({origen,destino,envio,paquetes,peso,precioProducto,pago,selected,
        nombreRecoleccion,nombreDestino,telefonoRecoleccion,telefonoDestino,mailRecoleccion,mailDestino,
        direccionRecoleccion,direccionDestino,fechaRecoleccion,fechaDestino,instruccionesRecoleccion,
        instruccionesDestino,tipoPago,nit,nombreNit,number,direccionFactura,banco,tipoCuenta,numeroCuenta,
        nombreCuenta,terminos,total,setSen});
    }
  };

  return (
    <div className="container-envioSolicitud">
      <Toast/>
      <h4>
        Servicio Solicitado: <span>{selected ? selected : "N/A"}</span>
      </h4>
      <Form
        className="form"
        onSubmit={handleSubmit}
      >
        <h1>
        Información <span>Del Remitente</span>
        </h1>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control disabled value={origen} />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="text"
            placeholder="Nombre Completo"
            onChange={(e) => setNombreRecoleccion(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="number"
            placeholder="Número Telefónico"
            onChange={(e) => setTelefonoRecoleccion(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="email"
            placeholder="Correo Electrónico"
            onChange={(e) => setMailRecoleccion(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="text"
            placeholder="Dirección Exacta de Recolección"
            onChange={(e) => setDireccionRecoleccion(e.target.value)}
          />
        </Form.Group>
        <div className="container-fecha">
        <DatePicker
          className="date-container"
          locale={es}
          showIcon
          icon="fa-regular fa-calendar"
          isClearable
          selected={fechaRecoleccion}
          onChange={(date) => setFechaRecoleccion(date)}
          placeholderText='Seleccione Fecha de Recolección'
          closeOnScroll={true}
          dateFormat="dd/MM/yy"
          filterDate={date => date.getDay() !== 0}
          minDate={addDays(new Date(),1)}
          withPortal
        />
        </div>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            as="textarea"
            rows={4}
            type="textarea"
            placeholder="Instrucciones Especiales Para La Recolección"
            onChange={(e) => setInstruccionesRecoleccion(e.target.value)}
          />
        </Form.Group>

        {/* SEGUNDO FORMULARIO */}

        <h1>
        Información <span>Del Destinatario</span>
        </h1>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control disabled value={destino} />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="text"
            placeholder="Nombre Completo"
            onChange={(e) => setNombreDestino(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="number"
            placeholder="Número Telefónico"
            onChange={(e) => setTelefonoDestino(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="email"
            placeholder="Correo Electrónico"
            onChange={(e) => setMailDestino(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="text"
            placeholder="Dirección Exacta de Entrega"
            onChange={(e) => setDireccionDestino(e.target.value)}
          />
        </Form.Group>
        <div className="container-fecha">
        <DatePicker
          className="date-container"
          locale={es}
          showIcon
          icon="fa-regular fa-calendar"
          isClearable
          selected={fechaDestino}
          onChange={date => setFechaDestino(date)}
          placeholderText='Seleccione Fecha de Entrega'
          closeOnScroll={true}
          dateFormat="dd/MM/yy"
          filterDate={date => date.getDay() !== 0}
          minDate={addDays(fechaRecoleccion,1)}
          maxDate={addDays(new Date(),20)}
          withPortal
        />
        </div>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            as="textarea"
            rows={4}
            type="textarea"
            placeholder="Instrucciones Especiales Para La Entrega"
            onChange={(e) => setInstruccionesDestino(e.target.value)}
          />
        </Form.Group>
        {tipoPagoVisible && (
          <Select 
          placeholder='Selecciona Forma de Pago ...'
          options= {TipoPago}
          onChange={ (e) => setTipoPago(e.value) }
          />
        )}

        {/* INFORMACION DE NIT */}

        <h1>
          Datos <span>de Factura</span>
        </h1>

        <Select
          placeholder='Desea NIT ...'
          options={Nit}
          onChange={(e) => setDeseaNit(e.value)}
        />
        {nitVisible &&
        <>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="text"
            placeholder="NIT"
            onChange={(e) => setNit(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Ingrese NIT
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="text"
            placeholder="Nombre"
            onChange={(e) => setNombreNit(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Control
            type="text"
            placeholder="Dirección"
            onChange={(e) => setDireccionFactura(e.target.value)}
          />
        </Form.Group>
        </>
        }

        {/* DATOS DE CUENTA BANCARIA */}

        { cuentaBancariaVisible && (
          <>
          <h1>
            Datos de<span> Cuenta Bancaria</span>
          </h1>
          <Select
            placeholder='Selecciona Un Banco ...'
            options= {Bancos}
            onChange={ (e) => setBanco(e.value) }
          />
          <Select
            placeholder='Selecciona Tipo De Cuenta ...'
            options= {TipoCuenta}
            onChange={ (e) => setTipoCuenta(e.value) }
          />
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Control
              type="text"
              placeholder="Número De Cuenta"
              onChange={(e) => setNumeroCuenta(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Control
              type="text"
              placeholder="Nombre de Cuenta"
              onChange={(e) => setNombreCuenta(e.target.value)}
            />
          </Form.Group>
          </>
        )}

        <div className="revision-datos">
          <p>Revise Datos de Solicitud</p>
          <i className="fa-solid fa-arrow-turn-down" />
        </div>

        <RevisionEnvio
          origen={origen}
          destino={destino}
          envio={envio}
          paquetes={paquetes}
          peso={peso}
          precioProducto={precioProducto}
          pago={pago}
          selected={selected}
          nombreRecoleccion={nombreRecoleccion}
          nombreDestino={nombreDestino}
          telefonoRecoleccion={telefonoRecoleccion}
          telefonoDestino={telefonoDestino}
          mailRecoleccion={mailRecoleccion}
          mailDestino={mailDestino}
          direccionRecoleccion={direccionRecoleccion}
          direccionDestino={direccionDestino}
          fechaRecoleccion={fechaRecoleccion?.toLocaleDateString()}
          fechaDestino={fechaDestino?.toLocaleDateString()}
          instruccionesRecoleccion={instruccionesRecoleccion}
          instruccionesDestino={instruccionesDestino}
          tipoPago={tipoPago}
          nit={nit}
          nombreNit={nombreNit}
          number={number}
          error={error}
          direccionFactura={direccionFactura}
          banco={banco}
          tipoCuenta={tipoCuenta}
          numeroCuenta={numeroCuenta}
          nombreCuenta={nombreCuenta}
          total={total}
        />


        {pdf &&
        <MyVerticallyCenteredModal
              destino={destino}
            pago={pago}
            selected={selected}
            nombreDestino={nombreDestino}
            telefonoDestino={telefonoDestino}
            direccionDestino={direccionDestino}
            fechaDestino={fechaDestino}
            instruccionesDestino={instruccionesDestino}
            tipoPago={tipoPago}
            number={number}
            total={total}
        />}

        {showModal && <BackToHome/>}

        {(errorMessage) ? <p className='error-msg'>Todos los campos son obligatorios! <i className="fa-solid fa-arrow-up-long" /></p> : ''}

        <Form.Group className="mb-3">
                <Form.Check
                    value={terminos}
                    onChange={(e) => setTerminos(e.target.checked)}
                    feedback="Debe estar deacuerdo con Terminos y Condiciones"
                    feedbackType="invalid"
                />
                <Link to='/terminos'>
                    <label className="label-terminos">Acepto Terminos y Condiciones</label>
                </Link>
            </Form.Group>

        {submit &&
        <button type="submit" className="btn-enviarSolicitud">
          Enviar Solicitud <i className="fa-solid fa-file-import" />
        </button>
        }
      </Form>
    </div>
  );
};
