import React from 'react'
import { Header } from '../Components/Header/Header'
import { BotonCotizador } from '../Components/BotonesPrincipal/BotonCotizador'
import { CardServicios } from '../Components/CardServicios/CardServicios'
import { Equipo } from '../Components/CardsEquipo/Equipo'
import { Footer } from '../Components/Footer/Footer'

export const Home = () => {
    return (
        <>
            <Header />
            <BotonCotizador />
            <CardServicios />
            <Equipo />
            <Footer />
        </>
    )
}
