import './EnvioSolicitud.css'

export const RevisionEnvio = ({
    origen,
    destino,
    envio,
    paquetes,
    peso,
    selected,
    precioProducto,
    pago,
    nombreRecoleccion,
    nombreDestino,
    telefonoRecoleccion,
    telefonoDestino,
    mailRecoleccion,
    mailDestino,
    direccionRecoleccion,
    direccionDestino,
    fechaRecoleccion,
    fechaDestino,
    instruccionesRecoleccion,
    instruccionesDestino,
    tipoPago,
    nit,
    nombreNit,
    number,
    banco,
    tipoCuenta,
    numeroCuenta,
    nombreCuenta,
    error,
    direccionFactura,
    total
}) => {

return (
    <div className='container-revision'>
        <div className='revision-paquete-remitente'>
            <h3>Recolección</h3>
            <p>Lugar de Recolección: <br/>
                <span>{origen}</span>
            </p>
            <p>Nombre de Quien Envia: <br/>
                <span>{nombreRecoleccion}</span>
            </p>
            <p>Número de Teléfono: <br/>
                <span>{telefonoRecoleccion}</span>
            </p>
            <p>Correo Electrónico Remitente: <br/>
                <span>{mailRecoleccion}</span>
            </p>
            <p>Dirección para Recolección: <br/>
                <span>{direccionRecoleccion}</span>
            </p>
            <p>Fecha de Recolección: <br/>
                <span>{fechaRecoleccion}</span>
            </p>
            <p>Instrucciones Especiales de Recolección: <br/> 
                <span>{instruccionesRecoleccion}</span>
            </p>
        </div>
        <div className='revision-paquete-destino'>
            <h3>Destino</h3>
            <p>Lugar de Destino: <br/>
                <span>{destino}</span>
            </p>
            <p>Nombre de Quien Recibe: <br/>
                <span>{nombreDestino}</span>
            </p>
            <p>Número de Teléfono: <br/>
                <span>{telefonoDestino}</span>
            </p>
            <p>Correo Electrónico Destinatario: <br/>
                <span>{mailDestino}</span>
            </p>
            <p>Dirección para Entrega: <br/>
                <span>{direccionDestino}</span>
            </p>
            <p>Fecha de Entrega: <br/>
                <span>{fechaDestino}</span>
            </p>
            <p>Instrucciones Especiales de Entrega: <br/>    
                <span>{instruccionesDestino}</span>
            </p>
        </div>
        <div className='revision-paquete'>
            <h3>Informacion de Paquete</h3>
            <p>Que Enviarás: <span>{envio}</span></p>
            <p>Cantidad de Paquetes: <span>{paquetes}</span></p>
            <p>Peso Estimado: <span>{peso}</span></p>
            <p>Precio Producto: <span>Q{precioProducto}</span></p>
            <p>Donde Pagarás: <span>{pago}</span></p>
        </div>
        <div className='revision-nit'>
            <h3>Datos de Nit</h3>
            <p>NIT: <span>{nit}</span></p>
            <p>Nombre: <span>{nombreNit}</span></p>
            <p>Dirección: <span>{direccionFactura}</span></p>
        </div>
        <div className='revision-banco'>
            <h3>Datos de Cuenta</h3>
            <p>Banco: <span>{banco}</span></p>
            <p>Tipo de Cuenta: <span>{tipoCuenta}</span></p>
            <p>Número de Cuenta: <span>{numeroCuenta}</span></p>
            <p>Nombre de Cuenta: <span>{nombreCuenta}</span></p>
        </div>
        <div className='revision-precio'>
            <h3>Pago</h3>
            <p>Como Pagarás: <span>{tipoPago}</span></p>
            <p>Orden: {number}</p>
            <p className='total-final'>Total: <span>Q{total}</span></p>
        </div>
    </div>
  )
}