export const MenuItems = [
    {
        title: 'C.O.D',
        path: '/COD',
        cName: 'dropdown-link'
    },
    {
        title: 'Estándar',
        path: '/estandar',
        cName: 'dropdown-link'
    }
];