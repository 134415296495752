export const Bancos = [
    {label: 'Banco Industrial', value: 'Banco Industrial'},
    {label: 'G&T Continental', value: 'G&T Continental'},
    {label: 'Banco de Desarrollo Rural', value: 'Banco de Desarrollo Rural'},
    {label: 'Banco De America Central', value: 'Banco De America Central'},
    {label: 'Banco Agromercantil de Guatemala', value: 'Banco Agromercantil de Guatemala'},
    {label: 'Banco Promerica', value: 'Banco Promerica'},
]

export const TipoCuenta = [
    {label: 'Monetaria', value: 'Monetaria'},
    {label: 'Ahorro', value: 'Ahorro'},
]

export const TipoPago = [
    {label: 'Efectivo', value: 'Efectivo'},
    {label: 'Deposito', value: 'Deposito'},
]

export const Nit = [
    {label: 'NIT', value: 'NIT'},
    {label: 'C/F', value: 'C/F'},
]