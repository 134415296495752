import React,{useState} from 'react'
import './Servicio.css';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

export const Servicio = () => {

  const [click, setClick] = useState(false);

  return (
    <>
    <div className='container-cardServicio'>
      <button onClick={setClick}>Ver Servicios<i className="fa-solid fa-hand-pointer"/></button>
    </div>
      <div>
      {click ?
      <>
        <Card>
        <Card.Body>
          <Card.Title>C.O.D</Card.Title>
          <Card.Text>
            <p>Servicio de Mensajería, mas cobro de producto en el lugar de destino.</p>
          </Card.Text>
          <Link to='/COD'>
          <button className='btn-recursos'>Ver Detalles del Servicio</button>
          </Link>
        </Card.Body>
      </Card>
      <Card>
      <Card.Body>
        <Card.Title>ESTANDAR</Card.Title>
        <Card.Text>
          <p>Servicio regular de Mensajería y Paquetería en territorio de cobertura.</p>
        </Card.Text>
        <Link to='/ESTANDAR'>
          <button className='btn-recursos'>Ver Detalles del Servicio</button>
        </Link>
      </Card.Body>
    </Card>
    </>
      : undefined
    }
    </div>
    </>
  )
}