import React, { useState, useEffect} from 'react'
import './FormContact.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { Toast } from '../Toast/Toast'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SendMail } from '../../API/api';
import Select from 'react-select';
import { areaInteres } from './DataInteres';


export const FormContact = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [interes, setInteres] = useState('');
    const [message, setMessage] = useState('');
    const [terms, setTerms] = useState('');
    const [send, setSend] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if(send){
            toast.success(send.msg);
            setFullName('');
            setEmail('');
            setPhone('');
            setInteres('');
            setMessage('');
            setTerms('');
            setSend();
        }
    }, [fullName, email, phone, interes, message, terms, send]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!fullName || !email || !phone || !interes || !message || !terms ) {
            setError(true);
            return;
        }
        else{
            setError(false)
            SendMail({fullName, email, phone, interes, message, terms, setSend});
        }
    };
    return (
    <>
        <div className='form-contact'>
            <Toast />
        <Form className='form' onSubmit={handleSubmit}>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Nombre Completo <span>*</span></Form.Label>
                <Form.Control
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    type="text"
                    placeholder="Nombre"
                />
                {/* <Form.Control.Feedback type='invalid'>
                    Ingrese Su Nombre
                </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Correo Electrónico <span>*</span></Form.Label>
                <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="ejemplo@mail.com"
                />
                {/* <Form.Control.Feedback type='invalid'>
                    Ingrese Su Correo
                </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group as={Col} md='4' controlId="validationCustom03">
                <Form.Label>Número Telefónico <span>*</span></Form.Label>
                <Form.Control 
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="number" 
                    placeholder="12345678"
                />
                {/* <Form.Control.Feedback type="invalid">
                    Ingrese Su Numero Telefonico
                </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group as={Col} controlId='formSelect' md='4'>
                <Form.Label>Área de Interés</Form.Label>
                <Select 
                    placeholder='Área de Interés ...'
                    options={areaInteres}
                    onChange={(e) => setInteres(e.value)}
                />
            </Form.Group>
            <Form.Group as={Col} md='4' controlId="exampleForm.ControlTextarea1">
                <Form.Label>Mensaje <span>*</span></Form.Label>
                <Form.Control
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    as="textarea" 
                    rows={4}
                />
                <Form.Control.Feedback type="invalid">
                    Ingrese Su Mensaje
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Check
                    value={terms}
                    onChange={(e) => setTerms(e.target.checked)}
                    feedback="Debe estar deacuerdo con Terminos y Condiciones"
                    feedbackType="invalid"
                />
                <Link to='/terminos'>
                    <label>Acepto Terminos y Condiciones</label>
                </Link>
            </Form.Group>

            {(error) ? <p className='error-msg'>Todos los campos son obligatorios!</p> : ''}
            <button className='btn-enviar-contacto' type="submit">Enviar <i className="fa-solid fa-paper-plane" /></button>
        </Form>

        <div className='social-media-contact-form'>
            <Link to='https://www.facebook.com/profile.php?id=100076178888075' target='_blank'><i className="fa-brands fa-facebook" /></Link>
            <Link to='https://www.instagram.com/tpc_express/' target='_blank'><i className="fa-brands fa-instagram" /></Link>
            <Link to='https://wa.me/58644597?text=Hola! Necesito Información' target='_blank'><i className="fa-brands fa-whatsapp" /></Link>
        </div>
        </div>
        <div className='acordion-form'>
            <h3>Preguntas <span>Frecuentes</span></h3>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>¿Como solicito el Servicio de Mensajería?</Accordion.Header>
                    <Accordion.Body>
                        Debes Llenar nuestro formulario de cotización, si estas de acuerdo realiza el envío,
                        al finalizar te llegará una notificación con un detalle de lo solicitado a tu correo electrónico,
                        solo debes esperar a que nuestra área de atención al cliente te contacte para corroborar datos y finalizar la solicitud.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>¿Cuáles son los servicios de envío que ofrece TPC EXPRESS?</Accordion.Header>
                    <Accordion.Body>
                        TPC EXPRESS ofrece 2 servicios de envío para satisfacer las necesidades de nuestros clientes. 
                        Esto incluye entrega con cobro de mercadería en el destino, servicios de paquetería estándar, así como 
                        opciones de envío personalizadas para adaptarse a diversos requerimientos logísticos (este último únicamente para empresas).
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>¿Cómo puedo rastrear mi envío con TPC EXPRESS?</Accordion.Header>
                    <Accordion.Body>
                        Para rastrear tu envío, debes llamar a nuestras oficinas al número 2298-1217, con tu número de envio
                        recibido en el detalle a tu correo electrónico, uno de nuestros agentes te dará la informacón necesaria.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>¿Cuánto tiempo se tarda en entregar un paquete con TPC EXPRESS?</Accordion.Header>
                    <Accordion.Body>
                        El tiempo estimado de entrega es de 24 a 48 horas, este puede variar por circunstancias externas a TPC EXPRESS.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>¿Cuáles son las medidas de seguridad para garantizar la integridad de los paquetes?</Accordion.Header>
                    <Accordion.Body>
                        En TPC EXPRESS, nos comprometemos a garantizar la seguridad e integridad de los paquetes que manejamos. Implementamos 
                        medidas de seguridad rigurosas, como el seguimiento constante, sistemas de control de calidad y la capacitación continua 
                        de nuestro personal para garantizar que cada envío llegue de manera segura a su destino.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>¿TPC EXPRESS ofrece servicios de logística para empresas?</Accordion.Header>
                    <Accordion.Body>
                        Sí, TPC EXPRESS ofrece soluciones de logística adaptadas a las necesidades de empresas. Nuestros servicios logísticos 
                        incluyen la gestión eficiente de la cadena de suministro, almacenamiento, distribución y servicios de cumplimiento. 
                        Podemos trabajar estrechamente con empresas para desarrollar soluciones logísticas personalizadas que optimicen sus operaciones.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </>
    );
}
