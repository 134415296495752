import React from 'react'
import { Link } from 'react-router-dom';

export const BackToHome = () => {

  return (
    <>
      <Link to='/'>
        <button className='regreso-principal'>Pagina Principal<i className="fa-solid fa-house" /></button>
      </Link>
    </>
  );
}


