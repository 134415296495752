import React from 'react'
import './InfoCod.css';
import { TabCobertura } from '../ApartadoCobertura/TabCobertura';
import { BotonServicios } from '../BtnSolicitaServicio/BotonServicios';
import { Link } from 'react-router-dom';

export const InfoCod = () => {
    return (
    <>
        <div className='container-infoCod'>
            <h3>¿De que <span>se trata?</span></h3>
            <div className='card-infoCod'>
                <div className='principal-cod-card'>
                    <i class="fa-solid fa-hand-holding-dollar" />
                    <p>
                        <ul>
                            <li>
                                Servicio de entrega y cobro de mercadería en el destino.
                            </li>
                            <li>
                                Comision del 3% sobre el valor de la mercadería.
                            </li>
                            <li>
                                Deposito en 24 horas Hábiles.
                            </li>
                            <li>
                                Diseñado para Emprendedores, Empresas o Personas que desean que se cobre el valor de la mercadería
                                al momento de la entrega.
                            </li>
                            <li>
                                Se cobra 1% de seguro (Sobre el valor de la mercadería), en caso de siniestro tu producto esta cubierto.
                            </li>
                        </ul>
                    </p>
                </div>
                <div className='comoFunciona-card'>
                    <h3>¿Como Funciona?</h3>
                    <p>
                        Llenas nuestro formulario para realizar tu envío, recibiras una
                        confirmación y detalle de tu pedido vía Correo Electrónico, nuestro equipo
                        se pondrá en contacto para confirmar el servicio y listo.
                    </p>
                </div>
                <div className='conficiones-card-cod'>
                    <h3>Condiciones:</h3>
                    <ul>
                        <li>Valor maximo de mercadería: Q5,000.</li>
                        <li>Debes tener cuenta bancaria válida y activa.</li>
                        <li>Se cobra 3% sobre el valor de la mercadería por el servicio de C.O.D</li>
                        <li>2 Intentos de Entrega.</li>
                        <li>Puedes cancelar el servicio (Antes de que sea recolectado)
                            sin penalización.
                        </li>
                        <li><Link to='/terminos'>Aplican Terminos y Condiciones</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='extra-info-cod'>
            <div>
                <h3>Servicio</h3>
                <p>C.O.D</p>
            </div>
            <div>
                <h3>Comisión C.O.D</h3>
                <p>3%</p>
            </div>
            <div>
                <h3>Recolección</h3>
                <i class="fa-solid fa-check" />
            </div>
            <div>
                <h3>Seguro</h3>
                <p>1%</p>
            </div>
        </div>
        <TabCobertura />
        <BotonServicios />
    </>
    )
}