import React from 'react'
import './InfoEstandar.css';
import { TabCobertura } from '../ApartadoCobertura/TabCobertura';
import { BotonServicios } from '../BtnSolicitaServicio/BotonServicios';
import { Link } from 'react-router-dom';

export const InfoEstandar = () => {
    return (
        <>
            <div className='container-infoEstandar'>
            <h3>¿De que <span>se trata?</span></h3>
            <div className='card-infoEstandar'>
                <div className='principal-estandar-card'>
                <i class="fa-solid fa-motorcycle" />
                    <p>
                        <ul>
                            <li>
                                Servicio de paquetería regular.
                            </li>
                            <li>
                                Puedes cancelar en origen, o destino (+Q3.00).
                            </li>
                            <li>
                                Plazo de entrega 24 a 48 horas.
                            </li>
                            <li>
                                Se cobra 1% de seguro (Sobre el valor de la mercadería), en caso de siniestro tu producto esta cubierto.
                            </li>
                        </ul>
                    </p>
                </div>
                <div className='comoFunciona-card-estandar'>
                    <h3>¿Como Funciona?</h3>
                    <p>
                        Llenas nuestro formulario para realizar tu envío, recibiras una
                        confirmación y detalle de tu pedido vía Correo Electrónico, nuestro equipo
                        se pondrá en contacto para confirmar el servicio y listo.
                    </p>
                </div>
                <div className='conficiones-card-estandar'>
                    <h3>Condiciones:</h3>
                    <ul>
                        <li>Peso de 1 - 10 Lbs.</li>
                        <li>Debes tener cuenta bancaria válida y activa</li>
                        <li>Se cobran Q3.00 si deseas que el envio se cobre en el destino.</li>
                        <li>2 Intentos de Entrega.</li>
                        <li>Recargo por peso adicional.</li>
                        <li>Puedes cancelar el servicio (Antes de que sea recolectado) 
                            sin penalización.
                        </li>
                        <li><Link to='/terminos'>Aplican Terminos y Condiciones</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='extra-info-estandar'>
            <div>
                <h3>Servicio</h3>
                <p>Estándar</p>
            </div>
            <div>
                <h3>Comisión C.O.D</h3>
                <p>N / A</p>
            </div>
            <div>
                <h3>Recolección</h3>
                <i class="fa-solid fa-check" />
            </div>
            <div>
                <h3>Seguro</h3>
                <p>1%</p>
            </div>
        </div>
        <TabCobertura />
        <BotonServicios />
    </>
    )
}