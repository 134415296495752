import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Img1 from '../../Assets/Images/tpc3.webp';
import Img2 from '../../Assets/Images/moto-tpc3.webp';
import Img3 from '../../Assets/Images/camion-tpc.webp';
import './Header.css';

export const Header = () => {

    return (
    <div className='carousel-container'>
        <Carousel fade>
            <Carousel.Item>
                <img src={Img1} alt='Slide'/>
                <Carousel.Caption>
                    <h3>Cobertura</h3>
                    <p>Las mejores rutas a nivel capitalino</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={Img2} alt='Slide'/>
                <Carousel.Caption>
                    <h3>Velocidad</h3>
                    <p>Entregas al siguiente día o cuando tu nos digas</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={Img3} alt='Slide'/>
                <Carousel.Caption>
                    <h3>Seguridad</h3>
                    <p>Personal capacitado, unidades adaptadas y cuidado de sus paquetes</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
    )
}
