import React, {useState, useEffect} from 'react'
import { CodForm } from './CodForm.js';
import { EstandarForm } from './EstandarForm.js'
import './Cotizador.css'

export const SeleccionServicio = ({
    origen,
    setOrigen,
    destino,
    setDestino,
    envio,
    setEnvio,
    paquetes,
    setPaquetes,
    peso,
    setPeso,
    precioProducto,
    setPrecioProducto,
    pago,
    setPago,
    selected,
    setSelected,
    cobroEnvio,
    setCobroEnvio,
    comision,
    setComision,
    total,
    setTotal
}) => {

    const [codContentVisible, setCodContentVisible] = useState(false);
    const [estandarContentVisible, setEstandarContentVisible] = useState(false);

    
    useEffect(() => {
        selected === "COD"
            ? setCodContentVisible(true)
            : setCodContentVisible(false);
        selected === "ESTANDAR"
            ? setEstandarContentVisible(true)
            : setEstandarContentVisible(false);
    }, [selected]);

    const handleChange = (e) => {
        setSelected(e.target.value);
    };

    

    return (
        <div className='container-cotizador-selectServicio'>
            <h1>Selecciona el <span>Servicio a Cotizar</span></h1>
            <div className='selectServicio'>
                <select className='form-selectServicio' value={selected} onChange={handleChange}>
                    <option value='seleccionaServicio'>Selecciona un Servicio</option>
                    <option value='COD'>C.O.D</option>
                    <option value='ESTANDAR'>Estándar</option>
                </select>
            </div>
            <div className='container-form--cotizador'>
                {codContentVisible && <CodForm 
                    origen={origen} setOrigen={setOrigen}
                    destino={destino} setDestino={setDestino}
                    envio={envio} setEnvio={setEnvio}
                    paquetes={paquetes} setPaquetes={setPaquetes}
                    peso={peso} setPeso={setPeso}
                    precioProducto={precioProducto} setPrecioProducto={setPrecioProducto}
                    cobroEnvio={cobroEnvio} setCobroEnvio={setCobroEnvio}
                    comision={comision} setComision={setComision}
                    total={total} setTotal={setTotal}
                />}
                {estandarContentVisible && <EstandarForm 
                    origen={origen} setOrigen={setOrigen}
                    destino={destino} setDestino={setDestino}
                    envio={envio} setEnvio={setEnvio}
                    paquetes={paquetes} setPaquetes={setPaquetes}
                    precioProducto={precioProducto} setPrecioProducto={setPrecioProducto}
                    peso={peso} setPeso={setPeso}
                    pago={pago} setPago={setPago}
                    cobroEnvio={cobroEnvio}
                    total={total} setTotal={setTotal}
                />}
            </div>
        </div>
    )
}