import React, {useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Pdf from './Pdf'
import './Pdf.css'

const MyVerticallyCenteredModal = ({
  destino,
  pago,
  selected,
  nombreDestino,
  telefonoDestino,
  mailDestino,
  direccionDestino,
  fechaDestino,
  instruccionesDestino,
  tipoPago,
  number,
  total
}) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <Button variant="primary" className='btn-principal-descarga' onClick={handleShow}>
      Descargar PDF
      <i className="fa-solid fa-file-arrow-down" />
      </Button>                 

    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Descargar PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>Por favor descargue el PDF generado con los datos de su envio,
        es importante que este sea pegado en el paquete para poder ser recolectado, si al momento de la recoleccion
        el paquete no se encuentra identificado no podra ser recolectado.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Pdf variant="primary"
          destino={destino}
          pago={pago}
          selected={selected}
          nombreDestino={nombreDestino}
          telefonoDestino={telefonoDestino}
          mailDestino={mailDestino}
          direccionDestino={direccionDestino}
          fechaDestino={fechaDestino}
          instruccionesDestino={instruccionesDestino}
          tipoPago={tipoPago}
          number={number}
          total={total}
        />
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default MyVerticallyCenteredModal